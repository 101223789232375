import packageJson from '../../package.json';

export const environment = {
  production: false,
  appName: 'Heat Exchanger Analysis Tool',
  version: packageJson.version,
  auth: {
    clientId: '007bc79f-07d7-45fa-8016-bc3b910f211b',
    authority: 'https://login.microsoftonline.com/chevron.onmicrosoft.com',
    redirectUri: 'https://heat-test.azure.chevron.com',
    postLogoutRedirectUri: 'https://heat-test.azure.chevron.com/auth/logout'
  },
  protectedResourceMap: [
    ['https://heat-api-test.azure.chevron.com/api', ['https://heatex-test.azure.chevron.com/user_impersonation']],
    [
      'https://heat-function-app.azurewebsites.net/api/HEX_Tool',
      ['https://heatex-test.azure.chevron.com/user_impersonation']
    ]
  ],

  apiEndpoint: 'https://heat-api-test.azure.chevron.com/api',
  functionAppEndPoint: 'https://heat-function-app.azurewebsites.net/api/HEX_Tool',
  scope: 'https://heatex-test.azure.chevron.com/user_impersonation',
  appInsights: {
    instrumentationKey: 'f8070c25-0712-43af-8f36-3e169209d9f8'
  }
};
